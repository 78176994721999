import { SearchBarViewProps } from './views/SearchBarView';
import { SearchResultsPageViewProps } from './views/SearchResultsPageView';

export const mountSearchBar = async ({ rootNode, props }: { rootNode: HTMLElement; props: SearchBarViewProps }) => {
	if (!(rootNode instanceof HTMLElement)) {
		throw new Error('rootNode must be an instance of HTMLElement');
	}
	const SearchBarView = await import(/* webpackChunkName:"searchBar" */ './views/SearchBarView').then(
		(module) => module.default,
	);
	return SearchBarView.renderSearchBar({ rootNode, props });
};

export const mountSearchResultsPage = async ({
	rootNode,
	props,
}: {
	rootNode: HTMLElement;
	props: SearchResultsPageViewProps;
}) => {
	if (!(rootNode instanceof HTMLElement)) {
		throw new Error('rootNode must be an instance of HTMLElement');
	}
	const SearchResultsPageView = await import(
		/* webpackChunkName:"searchResultsPage" */ './views/SearchResultsPageView'
	).then((module) => module.default);
	return SearchResultsPageView.renderSearchResultsPage({ rootNode, props });
};
